// Named Color Variables
$danger: #f53d3d;

$favorite: #69bb7b;

$ps-yellow: #f7b733;

$ps-inputgray: #757575;

$ps-white: #fff;

$ps-black: #000;

$ps-gray: #1f1f1f;

$ps-transparent-gray: #1f1f1fbf;

$ps-lightgray: #eee;

$ps-red: #f53d3d;

$ps-orange: #f15f22;

$ps-green: #69bb7b;

$transparent: rgba(255, 255, 255, 0);

$ps-dark-surface: #121212