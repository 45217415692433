.ps-background {
  background-color: $primary !important;
}
.ps-background-light {
  background-color: $light !important;
}
.ps-background-dark {
  background-color: $dark !important;
}

.ps-background-secondary {
  background-color: $secondary !important;
}
.ps-background-secondary-light {
  background-color: $secondary-light !important;
}
.ps-background-secondary-dark {
  background-color: $secondary-dark !important;
}

.ps-background-white {
  background-color: $ps-white !important;
}
.ps-background-gray {
  background-color: $ps-gray !important;
}
.ps-background-lightgray {
  background-color: $ps-lightgray !important;
}

.ps-text-white {
  color: $ps-white !important;
}
.ps-text-black {
  color: $ps-black !important;
}
.ps-secondary-text {
  color: $secondary !important;
}
.ps-text-red {
  color: $ps-red !important;
}
.inline {
  display: inline-block;
}

.ps-homepage-card-title {
  text-transform: uppercase;
}

a {
  text-decoration-line: none;
}
i.tiny {
  font-size: 1rem;
}


nav {
  border-top-color: $dark;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-top-width: 10px;
  box-shadow: 0 2px 2px 0 #00000024,0 1px 5px 0 #0000001f,0 3px 1px -2px #0003;
}
nav .brand-logo {
  padding: 0 0 0 5px !important;
}
.ps-container {
  width: 97% !important;
  max-width: 1920px !important;
}
.ps-card {
  padding: 5px 10px;
  &.MuiCard-root {
    overflow: visible;
  }
}

/* Homepage */
.record-counter span {
  margin: 0px 5px;
}

.record-counter {
  padding-bottom: 60px;
}

.large-icon {
   font-size: 4rem;
}

.ps-full-height {
  height: 100% !important;
  display: flex;
  flex-flow: column;
}

.ps-mfa-container {
  width: 75% !important;
  min-width: 300px !important;
  max-width: 800px !important;
}
.ps-mfa-input {
  width: 300px;
  max-width: 300px !important;
}

html,
body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100%;

  // When dark theme is on
  &.dark {
    // React Widgets
    .rw-input, .rw-popup, .rw-list-option {
      background-color: $ps-gray !important;
      color: $ps-white !important;
    }
    // Text
    .ps-text-white {
      color: $ps-black !important;
    }
    .ps-text-black {
      color: $ps-white !important;
    }
    .ps-form-header {
      color: $ps-white !important;
    }

    // Background
    .ps-background-white {
      background-color: $ps-black !important;
    }
    .ps-background-gray {
      background-color: $ps-lightgray !important;
    }
    .ps-background-lightgray {
      background-color: $ps-black !important;
    }

    // Form
    input {
      color: $ps-white;
    }

    // React Modal
    .ReactModal__Content {
      background-color: $ps-dark-surface !important;
      border: 1px solid $ps-gray !important;
    }
    .ReactModal__Overlay {
      background-color: $ps-transparent-gray !important;
    }


    // React Table
  	background-color: #202123;
  	color: #fff;
  	nav {
      background-color: $ps-gray;
    }
    .react-contextmenu-item {
      color: $ps-white !important;
    }
    .ReactTable input, select {
      background-color: #2D2D31 !important;
    }
  	.card {
  		background-color: $ps-dark-surface;
  	}
  	.divider {
  		opacity: 0.2;
  	}
  	.sidenav {
  		background-color: #2D2D31;
  		li {
  			a {
  				&:not(.subheader){
  					color: #89B2F5;
  					&:hover {
  						background-color: #3B4043;
  					}
  				}
  				&.subheader {
  					color:#9AA0A6;
  				}
  				.material-icons {
  					color: #9AA0A6;
  				}
  			}
  		}
  	}
  	.collection {
  		border: 1px solid rgba(255,255,255,0.2);
  		.collection-item {
  			background-color: rgba(255,255,255,0.2);
  			border-bottom: 1px solid rgba(255,255,255,0.2);;
  		}
    }
    .ReactTable .-pagination .-btn {
      color: $ps-white;
    }
    .ReactTable .rt-expander {
        display: inline-block;
        position: relative;
        margin: 0;
        color: transparent;
        margin: 0 10px;
    }

    .ReactTable .rt-expander:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-90deg);
        transform: translate(-50%, -50%) rotate(-90deg);
        border-left: 5.04px solid transparent;
        border-right: 5.04px solid transparent;
        border-top: 7px solid rgba(256, 256, 256, 0.8);
        transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
        cursor: pointer
    }

    .ReactTable .rt-expander.-open:after {
        -webkit-transform: translate(-50%, -50%) rotate(0);
        transform: translate(-50%, -50%) rotate(0)
    }
    .ReactTable .rt-noData {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.8);
        transition: all .3s ease;
        z-index: 1;
        pointer-events: none;
        padding: 20px;
        color: rgba(256, 256, 256, 0.5)
    }
    .ReactTable .rt-thead .rt-th.-sort-asc,
    .ReactTable .rt-thead .rt-td.-sort-asc {
        box-shadow: inset 0 3px 0 0 $ps-white;
    }

    .ReactTable .rt-thead .rt-th.-sort-desc,
    .ReactTable .rt-thead .rt-td.-sort-desc {
        box-shadow: inset 0 -3px 0 0 $ps-white;
    }
    .ReactTable.-striped .rt-tr.-odd {
        background: rgba(255, 255, 255, 0.03)
    }

    .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
        background: rgba(255, 255, 255, 0.05)
    }
    .ReactTable .-pagination .-btn:not([disabled]):hover {
        background: rgba(255, 255, 255, 0.3);
        color: #f0f0f0
    }

  }
/*************************** end darkTheme ***************************/
.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(256, 256, 256, 0.1);
}

.ReactTable * {
    box-sizing: border-box
}

.ReactTable .rt-table {
    -webkit-box-flex: 1;
    -ms-flex: auto 1;
    flex: auto 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto
}

.ReactTable .rt-thead {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
    background: rgba(255, 255, 255, 0.03);
    border-bottom: 1px solid rgba(256, 256, 256, 0.05)
}

.ReactTable .rt-thead.-filters {
    border-bottom: 1px solid rgba(256, 256, 256, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
    border: 1px solid rgba(256, 256, 256, 0.1);
    background: #f0f0f0;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none
}

.ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid rgba(256, 256, 256, 0.02)
}

.ReactTable .rt-thead.-header {
    box-shadow: 0 0 0 0
}

.ReactTable .rt-thead .rt-tr {
    text-align: center
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: 1px solid rgba(256, 256, 256, 0.05);
    transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
    cursor: pointer
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
    border-right: 0
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
    overflow: hidden
}

.ReactTable .rt-thead .rt-resizable-header-content {
    overflow: hidden;
    text-overflow: ellipsis
}

.ReactTable .rt-thead .rt-header-pivot {
    border-right-color: #080808
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.ReactTable .rt-thead .rt-header-pivot:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #f0f0f0;
    border-width: 8px;
    margin-top: -8px
}

.ReactTable .rt-thead .rt-header-pivot:before {
    border-color: rgba(153, 153, 153, 0);
    border-left-color: #080808;
    border-width: 10px;
    margin-top: -10px
}

.ReactTable .rt-tbody {
    -webkit-box-flex: 99999;
    -ms-flex: 99999 1 auto;
    flex: 99999 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px rgba(256, 256, 256, 0.05);
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
    border-bottom: 0
}

.ReactTable .rt-tbody .rt-td {
    border-right: 1px solid rgba(256, 256, 256, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
    border-right: 0
}

.ReactTable .rt-tbody .rt-expandable {
    cursor: pointer
}

.ReactTable .rt-tr-group {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.ReactTable .rt-tr {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width, min-width, padding, opacity;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important
}

.ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10
}

.ReactTable .rt-tfoot {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    box-shadow: 0 0 0 0;
}

.ReactTable .rt-tfoot .rt-td {
    border-right: 1px solid rgba(256, 256, 256, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
    border-right: 0
}

.ReactTable .-pagination {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0 0 0;
    border-top: 2px solid rgba(256, 256, 256, 0.1);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
    border: 1px solid rgba(256, 256, 256, 0.1);
    background: #f0f0f0;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none
}

.ReactTable .-pagination .-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: $ps-black;
    background: rgba(255, 255, 255, 0.1);
    transition: all .1s ease;
    cursor: pointer;
    outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
    opacity: .5;
    cursor: default
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center
}

.ReactTable .-pagination .-center {
    -webkit-box-flex: 1.5;
    -ms-flex: 1.5;
    flex: 1.5;
    text-align: center;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.ReactTable .-pagination .-pageInfo {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap
}

.ReactTable .-pagination .-pageJump {
    display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
    width: 70px;
    text-align: center
}

.ReactTable .-pagination .-pageSizeOptions {
    margin: 3px 10px
}

.ReactTable .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: all .3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
}

.ReactTable .-loading > div {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(256, 256, 256, 0.6);
    -webkit-transform: translateY(-52%);
    transform: translateY(-52%);
    transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.ReactTable .-loading.-active {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
}

.ReactTable .-loading.-active > div {
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
    transition: none !important;
    cursor: col-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  margin: 0;
  padding: 0;
  min-width: 100%;
  min-height: 100%;
}

#root > div {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.route-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

/* Side Nav */
.side-nav-and-content-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 100%;
}

.ps-side-nav {
  position: relative;
  float: left;
  width: 90px;
  /* reset style */
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s 0s, visibility 0s 0s;
}

@media only screen and (min-width: 768px) {
  .ps-side-nav {
    position: relative;
    float: left;
    width: 110px;
    /* reset style */
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s 0s, visibility 0s 0s;
  }
}

@media only screen and (min-width: 900px) {
  .ps-side-nav {
    position: relative;
    float: left;
    width: 220px;
    /* reset style */
    visibility: visible;
    opacity: 1;
  }
}

/* Screen size */

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
  }
  .ReactTable .-pagination .-btn {
    width: auto;
    height: auto;
  }
  .ReactTable .-pagination .-pageInfo {
    margin: 3px 3px;
  }
}

.main-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  overflow: hidden;
}

/* Materialize color overrides */

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $light;
  -webkit-box-shadow: 0 1px 0 0 $light;
  box-shadow: 0 1px 0 0 $light;
}

.MuiButton-root {
  color: $ps-white;
}
.MuiButton-root:hover,
.MuiButton-root-large:hover {
  background-color: $light;
}
.MuiButton-root-small,
.MuiButton-root-small .MuiButton-root-floating i,
.MuiButton-root-small > i {
  font-size: 1.3rem;
  height: 24px;
  width: 24px;
  line-height: 24px;
  padding-bottom: 5px;
}

/* React Table */

.ReactTable {
  border: none;
  border-right: none;
  margin-top: 10px;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none;
}
.ReactTable .rt-thead.-header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td,
.ReactTable .rt-thead.-filters .rt-th {
  border-right: none;
}

.ReactTable .-pagination {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-top: none;
}
.ReactTable .-pagination .-btn {
  width: 100%;
  height: auto;
}

.ReactTable .-pagination .-pageJump input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
  margin-bottom: 0px;
  text-align: center;
  height: auto;
}
.ReactTable select {
  display: block !important;
  height: auto;
}
.ReactTable .rt-noData {
  z-index: auto;
}

.ReactTable input,
.ReactTable select {
  margin: 5px 0 5px 0 !important;
  height: auto !important;
}
.ReactTable input {
  border: none !important;
  border-bottom: 1px solid #9e9e9e !important;
}
/* React Context Menu */
.react-contextmenu {
  width: 160px !important;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 16px;
  color: #373a3c;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  outline: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease !important;
  z-index: 999 !important;
}

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
}

.ps-cursor-pointer {
  cursor: pointer;
}

.react-contextmenu-item {
  width: 160px !important;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #373a3c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
  color: #fff;
  background-color: #20a0ff;
  border-color: #20a0ff;
  text-decoration: none;
}

.react-datepicker-popper {
  z-index: 2;
}
